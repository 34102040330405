.feam-app {
  height: 100%;
  display: flex;
  position: relative;
  background-color: #25313e !important;
}

.feam-app-centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.feam-app-centerBox {
  border-radius: 12px;
  background-color: white;
  max-width: 500px !important;
  text-align: center;
}
.feam-app-aircraftbg {
  background: url("./assets/images/aircraft.jpg") no-repeat top center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.feam-app-divBtn {
  border: solid 2px #f47d30;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: 1px;
  text-align: center;
  color: #5e5e5e;
}

.feam-app-centerBox .h1 {
  font-size: 48px;
  color: #121212;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.feam-app-centerBox .h1,
.feam-app-centerBox .h4 {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
}
