.feam-sugesstion0-auto {
  display: block;
  position: relative;
}
.feam-suggestions0 {
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 999;
  cursor: pointer;
  max-height: 200px;
  min-width: 300px;
  overflow: auto;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
a.feam-suggestions0-item:active,
a.feam-suggestions0-item:hover,
a.feam-suggestions0-item:focus,
a.feam-suggestions0-item:focus-visible,
a.feam-suggestions0-item:focus-within {
  outline: none;
  outline-color: none;
  outline-style: none;
  outline-width: none;
  background-color: #6c757d;
  color: #ffffff;
}

.feam-suggestions0 > div {
  padding: 0.25rem;
  border-bottom: 2px solid #2d2e36;
  transition: all 0.1s ease;
}

.feam-suggestions0 > div:hover {
  background-color: #dbdbdb !important;
  color: #000000;
}

.feam-suggestions0 > div {
  font-size: 14px !important;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 4 !important;
  color: #ffffff;
  border: none;
}
input::-webkit-date-and-time-value {
  text-align: left;
}