.customer-worksheet-container {
  border: 1px solid black;
  padding: 1px;
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  font-size: small;
  margin: 5px;
  background-color: white;
}
.customer-worksheet-container-min-height {
  min-height: 1290px !important;
}
.customer-worksheet-container-ios-min-height {
  min-height: 900px !important;
}
.customer-container-width {
  width: 997px;
}

.customer-worksheet-table-container {
  width: 100%;
  height: 100%;
  padding: 5px;
}

.customer-worksheet-version-container {
  width: 94px;
}

.customer-worksheet-version-fontsize {
  font-size: x-small;
}

.customer-worksheet-singletail-label-control-width {
  width: 130px;
}

.customer-worksheet-singletail-control-width {
  width: 100px;
}

.customer-worksheet-singletail-min-control-width {
  width: 35px;
}
.customer-worksheet-search-control {
  font-size: small !important;
}
.customer-worksheet-form-control {
  padding: 0rem 0.25rem !important;
  font-size: small !important;
}

.customer-worksheet-form-select {
  padding: 0rem 2.25rem 0rem 0.75rem !important;
  font-size: small !important;
}

.customer-worksheet-form-ntcontrol {
  padding: 0rem 0px !important;
  font-size: smaller !important;
}

.customer-worksheet-mname {
  width: 185px !important;
}
.customer-worksheet-ws-mname {
  width: 300px !important;
}
.customer-worksheet-ws-date {
  width: 130px !important;
}
.customer-worksheet-ws-date-time {
  width: 170px !important;
}
.customer-worksheet-ws-workshift {
  width: 85px !important;
}
.customer-worksheet-form-tcontrol {
  padding: 0px 0px 0px 2px !important;
  font-size: small !important;
}
.customer-worksheet-ws-label {
  padding: 4px 0px 0px;
}
.customer-worksheet-mtime {
  width: 68px !important;
}

.customer-worksheet-mselect {
  width: 76px !important;
}

.customer-worksheet-container
  input[type="time"]::-webkit-calendar-picker-indicator {
  width: 7px !important;
  align-content: center !important;
  justify-content: center !important;
  padding: 0.25rem 2px 0px !important;
}

.customer-worksheet-mgap {
  gap: 1px;
}
.customer-worksheet-ws-mgap {
  gap: 10px;
}

.customer-worksheet-mselect .form-select {
  padding: 0rem 1px !important;
  font-size: small !important;
}

.customer-worksheet-label {
  padding: 2px 1px !important;
}
.customer-worksheet-multiletail-control-width {
  width: 70px !important;
}
.customer-worksheet-multiletail-bg-control-width {
  width: 100px !important;
}
.customer-worksheet-multiletail-min-control-width {
  width: 32.5px;
}
.customer-worksheet-dynamic-tail-min-control-width {
  width: 55px;
}

.customer-worksheet-multiletail-gap {
  gap: 3px;
}

.customer-worksheet-container .form-check .form-check-input[type="checkbox"],
.customer-worksheet-tail-form .form-check .form-check-input[type="checkbox"],
.customer-tail-detail .form-check .form-check-input[type="checkbox"],
.feam-advance-search .form-check .form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  height: 20px;
  width: 20px;
}
.cws-advance-search {
  min-height: 100vh !important;
  min-width: 997px;
}
.customer-worksheet-container .header-col,
.customer-worksheet-tail-form .header-col,
.mechanics-form .header-col,
.cws-advance-search .header-col,
.expenses-form .header-col,
.feam-tree-view .header-col,
table > tr.header-col,
table > th.header-col,
.customer-worksheet-time-labor tr.header-col,
.customer-worksheet-time-labor th.header-col {
  background-color: rgba(241, 243, 244, 1) !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: small !important;
}

.customer-worksheet-tail-form {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: small !important;
}

.customer-worksheet-container input::placeholder {
  font: small !important;
}

.customer-worksheet-container .m-hours-width {
  width: 40px !important;
}
.customer-worksheet-container .m-select-width {
  width: 62px !important;
}
.customer-worksheet-container .m-ws-hours-width {
  width: 85px !important;
}
.customer-worksheet-container .m-ws-select-width {
  width: 100px !important;
}
.customer-worksheet-button-card {
  width: 18rem;
}
.customer-worksheet-card {
  width: 18rem;
  padding: 2px;
  background-color: rgba(241, 243, 244, 1) !important;
}
.customer-worksheet-card .card-img-bottom {
  height: 182px;
}
.customer-worksheet-card-img {
  background-color: #868e96;
}
.customer-worksheet-gen-label-width {
  min-width: 105px !important;
}
.customer-worksheet-form-auto-control {
  padding: 0rem 0rem !important;
  font-size: small !important;
}
.dynamic-tail-table tr th {
  padding: 2px 5px !important;
}

.dynamic-tail-table tr td,
.dynamic-tail-table tr th,
.dynamic-tail .card-header,
.dynamic-tail .card-body {
  padding: 0px 5px !important;
}
.dynamic-tail .card-body .row {
  padding: 2px 0px !important;
}

table.dynamic-tail-table {
  border-collapse: collapse;
  border-radius: 0.25rem;
  padding: 0.25rem;
}

table.dynamic-tail-table th:first-child {
  /* border-radius = top left, top right, bottom right, bottom left */
  border-radius: 0.25rem 0 0 0; /* curves the top left */
  padding-left: 0.25rem;
}

table.dynamic-tail-table th:last-child {
  border-radius: 0 0.25rem 0 0; /* curves the top right */
}
.dynamic-tail-table tr.header-col th {
  background-color: rgba(241, 243, 244, 1);
}
.advanced-search-table tr.header-col th {
  background-color: rgba(241, 243, 244, 1);
}
.padding-1 {
  padding: 1px !important;
}
.w-90 {
  width: 90px;
}
.min-with-100 {
  min-width: 100px;
}
@media print {
  * {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  .btn {
    cursor: pointer !important;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
}

.btn-light-secondary {
  --bs-btn-color: black !important;
  --bs-btn-bg: rgba(241, 243, 244, 1) !important;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgb(203 210 213) !important;
  --bs-btn-hover-border-color: rgb(203 210 213) !important;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgb(203 210 213) !important;
  --bs-btn-active-border-color: rgb(203 210 213) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #rgba(241, 243, 244, 1) !important;
  --bs-btn-disabled-border-color: #rgba(241, 243, 244, 1) !important;
}

table.table-ws tr,
td,
th {
  padding: 2px !important;
  margin: 0px !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}

table tr,
td,
th {
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
}

.customer-worksheet-header {
  height: 69px;
}

.customer-worksheet-version-container-cvg {
  width: 120px;
}
.customer-worksheet-logo {
  height: 65px;
  width: 162px;
}
.feam-suggestion-auto {
  display: block;
  position: relative;
}
.feam-suggestions {
  position: absolute;
  top: 26px;
  left: 0px;
  z-index: 999;
  cursor: pointer;
  max-height: 200px;
  width: 100%;
  overflow: auto;
}
a.feam-suggestions-item:active,
a.feam-suggestions-item:hover,
a.feam-suggestions-item:focus,
a.feam-suggestions-item:focus-visible,
a.feam-suggestions-item:focus-within {
  outline: none;
  outline-color: none;
  outline-style: none;
  outline-width: none;
  background-color: #0d6efd !important;
  color: #ffffff;
}

.feam-suggestions > div {
  padding: 0.25rem;
  border-bottom: 2px solid #2d2e36;
  transition: all 0.1s ease;
}

.feam-suggestions > div:hover {
  background-color: #0d6efd !important;
  color: white;
}

.feam-suggestions > div {
  font-size: small !important;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif !important;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 4 !important;
  color: #ffffff;
  border: none;
}

.feam-suggestion-auto .list-group,
.feam-suggestion-auto .list-group-item {
  display: block;
}
.feam-suggestion-auto .list-group-item {
  padding: 2px 0px 2px 10px;
}

li.feamAutoComplete {
  font-size: small !important;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif !important;
  padding: 0.025rem 0px 0.025rem 10px !important;
  margin: 0px 0px 1px 0px;
  color: #fff;
  background-color: grey !important;
}
li.feamAutoComplete:focus,
li.feamAutoComplete:hover,
li.feamAutoComplete:active,
li.feamAutoComplete:focus-within,
li.feamAutoComplete:focus-visible {
  background-color: #0d6efd !important;
  color: white !important;
}

table.advanced-search-table thead button.ascending::after {
  content: "\2193";
  display: inline-block;
  margin-left: 0.25em;
}

table.advanced-search-table thead button.descending::after {
  content: "\2191";
  display: inline-block;
  margin-left: 0.25em;
  color: grey;
}
table.advanced-search-table td,
table.advanced-search-table th {
  text-align: center;
}
.feam-postion-fixed ._loading_overlay_overlay {
  position: fixed !important;
  min-width: 1290px;
  width: 100%;
  height: 100%;
}
.feam-customerD407-search-result-bg-color {
  background-color: #b8dbc2 !important;
}
.feam-customerD407-search-notfound-result-bg-color {
  background-color: #fcf5c7 !important;
}
.feam-m-5 {
  margin: 5px;
}
.customD407-control-with-107 {
  width: 107px !important;
}
.customD407-control-with-67 {
  width: 67px;
}
.customD407-control-with-98 {
  width: 98px;
}
.customD407-control-with-75 {
  width: 75px;
}
.customD407-control-with-237 {
  width: 237px;
}
.feam-padding-5 {
  padding: 5px;
}
.feam-mb-2 {
  margin-bottom: 2px;
}
.feam-mt-2 {
  margin-top: 2px;
}

input::-webkit-date-and-time-value {
  text-align: left;
}
.w-150 {
  width: 150px !important;
}
.w-120 {
  width: 120px !important;
}
.feam-title-btn-padding {
  padding: 1px 10px !important;
}
.feam-font-size-sm {
  font-size: small !important;
}

.feam-process-status-size {
  width: 140px;
}
.feam-solid-border {
  border: 1px solid black !important;
}
.feam-checkbox-table-align {
  padding-left: 2.35em !important;
}
.flightInfo .MuiInputBase-root {
  width: 107px !important;
}
.flightInfo .MuiInputBase-root input[type="text"] {
  text-align: center !important;
}

.search-auto-input .MuiAutocomplete-inputRoot {
  padding-right: 39px !important;
  /* margin: 0px; */
  /* height: 21.5px; */
  font-size: small !important;
  border: none !important;
  width: 200px !important;
  height: 38px !important;
}
.search-auto-input .form-control:focus-visible,
.search-auto-input .form-control:hover,
.search-auto-input .MuiAutocomplete-inputRoot:focus-visible,
.search-auto-input .MuiAutocomplete-inputRoot:hover,
.search-auto-input
  .MuiAutocomplete-inputRoot
  .MuiAutocomplete-input:focus-visible,
.search-auto-input .MuiAutocomplete-inputRoot .MuiAutocomplete-input:hover,
.search-auto-input .MuiAutocomplete-inputRoot [type="input"]:focus-visible,
.search-auto-input .MuiAutocomplete-inputRoot [type="input"]:hover {
  border-color: #dee2e6 !important;
}

.feam-gap-5-25 {
  gap: 3.25rem !important;
}
