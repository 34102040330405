ul.feam-tree-view,
ul.feam-tree-view li{
    list-style-type: none;
}
.feam-tree-view {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.feam-tree-view .caret {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  font-weight: bold;
}

.feam-tree-view .caret::before {
  content: "\002B";
  color: black;
  display: inline-block;
  margin-right: 6px;
  font-weight: bold;
  font-size: large;
}

.feam-tree-view .caret-down::before {
  content: "\2212";
  font-size: large;
}

.feam-tree-view .nested {
  display: none;
}

.feam-tree-view .active {
  display: block;
}
