
.customer-worksheet-card {
    padding: 2px;
    background-color: rgba(241, 243, 244, 1) !important;
  }
  .customer-worksheet-card .card-img-bottom {
    height: 150px;
    width: 150;
  }
  .customer-worksheet-card-img {
    background-color: #868e96;
  }