.feam-footer {
  background-color: #23314a;
  color: grey !important;
  min-width: 1200px !important;
  height: 90px;
}
.feam-footer-link {
  color: grey !important;
  text-decoration: none;
}
.feam-footer-copy-link{
  color: #ff690d !important;
  text-decoration: none;
}
